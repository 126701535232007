<template>
  <v-container>
  <v-row>
    <v-file-input multiple accept="image/*" @change="uploadFiles"></v-file-input>
    
  </v-row>
  <v-row>
  <v-col cols="12" v-for="(upload, index) in uploads" :key="index">
    <v-progress-linear :value="upload.progress" height="20" striped>
      <div class="text--small text-start">{{ upload.file.name }}</div>
    </v-progress-linear>
  </v-col>
</v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      uploads: []
    };
  },
  methods: {
     uploadFiles(files) {
      
      console.log(files);
      for (let file of files) {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("product", "jigsaw2");

        this.uploads.push({
          file,
          progress: 0
        });

         this.uploadFile(formData, this.uploads.length - 1);
      }
    },
    uploadFile(formData, index) {
      var that = this;
      try {
         axios.post("/admin/api/uploadAndCreate", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressEvent => {
            this.uploads[index].progress =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        }).then((response) => {
          console.log(response);
          that.uploads[index].uploadDone = true;
          that.uploads[index].progress = 100;
          let alldone = that.uploads.every((upload) => {
            return upload.uploadDone;
          });
          if(alldone){
            that.$emit('upload-done');
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  },
  beforeDestroy() {
    this.uploads = [];
  }
};
</script>
