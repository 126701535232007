<template>
    <nav>
        <v-snackbar v-model="snackbar" inline :timeout="2500" top :color="snackColor">
            {{snackMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                X
                </v-btn>
            </template>
        </v-snackbar>
        <v-navigation-drawer
            v-model="drawer" app>
            <v-list-item class="my-6">
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        配置后台
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list nav class="my-6" >
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item link @click="$router.push('/').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-puzzle</v-icon></v-list-item-icon>
                        <v-list-item-title>拼图</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$router.push('/finddifference').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-vector-difference</v-icon></v-list-item-icon>
                        <v-list-item-title>找茬</v-list-item-title>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/nonogram').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-grid</v-icon></v-list-item-icon>
                        <v-list-item-title>NONOGRAM</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$router.push('/dicejigsaw').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-dice-5</v-icon></v-list-item-icon>
                        <v-list-item-title>Dice Jigsaw</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$router.push('/poolclash').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-album</v-icon></v-list-item-icon>
                        <v-list-item-title>Pool Clash</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$router.push('/appmeta').catch(()=>{})">
                        <v-list-item-icon><v-icon>mdi-server-security</v-icon></v-list-item-icon>
                        <v-list-item-title>强制更新</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

        </v-navigation-drawer>
        <v-app-bar app flat>
            
            <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isLogin"></v-app-bar-nav-icon>
              <v-toolbar-title class="text-uppercase grey--text">
                <span class="font-weight-light">Back</span>
                <span>End</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="isLogin">
                 <v-btn text color="grey" @click="signout">
                    <span>{{userInfo.name}}</span>
                    <v-icon right>mdi-exit-to-app</v-icon>
                </v-btn>
            </template>
            <template v-else>
                 <v-btn text color="grey" @click="login">
                    <span>Login</span>
                    <!-- <v-icon right>mdi-exit-to-app</v-icon> -->
                </v-btn>
            </template>
        </v-app-bar>
    </nav>
</template>

<script>
import { bus } from '@/main'
import axios from 'axios'

export default{
    name : "Navbar",
    data: function(){
        return {
            selectedItem: 0,
            drawer: false,
            isLogin: false,
            userInfo: null,
            snackMessage: "",
            snackbar: false,
            snackColor: "success",
        }
    },
    methods: {
        signout: function(){
            var vm = this;
            axios.get("/admin/logout").then(function(response){
                console.log(response.data)
                if (response.data.status === 0){
                    bus.$emit("userInfoChange", {}, false);
                    vm.$router.push('/login');
                }
            })
            // console.log(event)
        },
        login: function(){
            this.$router.push("/login")
        },
        showSnackMessage:function(msg, error=false){
            if(error === false){
                this.snackColor = "success";
            }else{
                this.snackColor = "error";
                
            }
            this.snackMessage = msg;
            this.snackbar = true; 
        },
        userInfoChange: function(userInfo, login=true){
            this.userInfo = userInfo;
            this.isLogin = login;
        },
    },
    created: function(){
        bus.$on("showSnackMessage", this.showSnackMessage);
        bus.$on("userInfoChange", this.userInfoChange);
    },
}
</script>
<style>


</style>