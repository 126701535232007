import Vue from 'vue'
import VueRouter from 'vue-router'
import JigsawConfig from '../views/JigsawConfig.vue'
import FindDifference from '../views/FindDifference.vue'
import Nonogram from '../views/Nonogram.vue'
import DiceJigsaw from '../views/DiceJigsaw.vue'
import AppMeta from '../views/AppMeta.vue'
import PoolClash from '../views/PoolClash.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'JigsawConfig',
    component: JigsawConfig
  },
  {
    path: '/finddifference',
    name: 'FindDifference',
    component: FindDifference
  },
  {
    path: '/poolclash',
    name: 'PoolClash',
    component: PoolClash
  },
  {
    path: '/login',
    name: "login",
    component: Login
  },
  {
    path: '/nonogram',
    name: 'nonogram',
    component: Nonogram,
  },
  {
    path: '/dicejigsaw',
    name: 'dicejigsaw',
    component: DiceJigsaw,
  },
  {
    path: '/appmeta',
    name: 'appmeta',
    component: AppMeta,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
