<template>
    <v-container>
        <v-dialog width="800px" v-model="magnifyImgDialog">
            <v-card>
                <v-img :src="magnifyImgUrl"></v-img>
            </v-card>

        </v-dialog>

       <v-data-table
        :headers = "headers"
        :items = "items"
        :options.sync="options"
        :server-items-length="serverLength"
        :loading="loading"
        loading-text="加载中..."
        :search="search"
        class="elevation-2"
       > 
       <template v-slot:item.select="{ item }">
           <v-checkbox
            v-model="multiselectItems"
            :value="item.id"
            :input-value="item.id"
            color="primary"
            hide-details
           ></v-checkbox>
        </template>
       <template v-slot:item.isFree="{ item }">
            <v-switch
                color="indigo"
                v-model="item.isFree"
                @change="handleIsFreeChange(item)"
           
            ></v-switch>
           <!-- <v-chip
            :color="getIsFreeColor(item.isFree)"
           >{{getIsFreeContent(item.isFree)}}</v-chip> -->
       </template>
       <template v-slot:item.isAd="{ item }">
           <v-chip
            :color="getIsAdColor(item.isAd)"
           >{{getIsAdContent(item.isAd)}}</v-chip>
       </template>
       <template v-slot:item.collections="{ item }">
           <v-chip v-for="collection, index in item.collections" :key="index">{{collection}}</v-chip>
       </template>
       <template v-slot:item.modifyTime="{ item }">
           <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
       </template>
       <template v-slot:item.onlineDate="{item}">
            <v-chip :color='getOnlineDateColor(item.onlineDate || "")'>{{item.onlineDate||""}}</v-chip>
       </template>
       <template v-slot:item.url="{ item }">
           <v-img @click="magnifyImage(item)" class="rounded-lg" max-width="60" max-height="38" :src="item.url+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
       </template>
       <template v-slot:item.regions="{ item }">
            <v-chip v-for="region, index in item.regions" :key="index">{{getCNNameByCode(region)}}</v-chip>
       </template>
       <template v-slot:item.actions="{ item }">
           <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
           >
                mdi-pencil
           </v-icon>
           <v-icon
                small
                @click="deleteItem(item)"
           >
                mdi-delete
           </v-icon>
           <v-icon
                small
                @click="makefirst(item)"
           >
                mdi-arrow-up-bold
           </v-icon>
       </template>
       
        

        <template v-slot:top>
           <v-toolbar flat>
               <v-toolbar-title>Picture</v-toolbar-title>
               <v-divider vertical inset class="mx-4"></v-divider>
               
               <v-btn 
                        color="primary"
                        dark
                        outlined
                        class="mr-4"
                        @click="dialogMultiModify=true"
               >
               <v-badge :content="multiselectItems.length" :value="multiselectItems.length" color="error">
                    批量操作
                </v-badge>              
            </v-btn>
                <v-dialog v-model="dialogMultiModify" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">批量操作此{{ multiselectItems.length }} 个图片</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-menu
                                            ref="menu"
                                            v-model="date_picker_menu"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="modifiedOnlineDate"
                                                    label="修改上线时间"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="modifiedOnlineDate"
                                                no-title
                                                scrollable
                                            >
                                            <v-row>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="date_picker_menu = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                            </v-row>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            
                            <v-btn color="error" @click="doMultiModifyDelete">删除(慎!不会再次确认)</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1"  @click="dialogMultiModify=false">取消</v-btn>
                            <v-btn color="blue darken-1"  @click="doMultiModifyOnlineDate">确认</v-btn>
                            
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               <v-spacer></v-spacer>
               <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                     <v-img class="mb-1" v-if="editedItem.url != ''"
                            height="200"
                            :src="editedItem.url"
                        >

                    </v-img>
                    <v-card-title class="text-h5">确认要删除?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">确认</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog 
                    v-model="multiupload" 
                    persistent
                    max-width="500px">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                        color="primary"
                        dark
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        class="mr-4"
                        >批量上传</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>批量上传图片</v-card-title>
                        <v-card-text>
                            <multi-uploadfile v-if="multiupload" @upload-done="reloadData"/>                           
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="multiupload=false"
                            >
                                关闭
                            </v-btn>
                        </v-card-actions>       
                    </v-card>
                </v-dialog>

               <v-dialog 
                    v-model="dialog" 
                    persistent
                    max-width="500px"
               >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        >
                            新建
                        </v-btn>
                    </template>
                    <v-card>
                        <v-img class="mb-1" v-if="editedItem.url != ''"
                            height="200"
                            :src="editedItem.url"
                        >

                        </v-img>

                        <v-card-title v-if="editedItem.url == ''">{{ dialogTitle }}</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-file-input
                                            label="上传图片"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                            @change="fileuploadChange"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col>
    
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Url"
                                            v-model="editedItem.url"
                                            :rules="urlRules"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Hash"
                                            v-model="editedItem.hash"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox
                                            v-model="editedItem.isFree"
                                            label = "免费"
                                            @change="isFreeChange"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox
                                            v-model="editedItem.isAd"
                                            :disabled="editedItem.isFree"
                                            label="广告"
                                        ></v-checkbox>
                                    </v-col>
                                     <v-col cols="4">
                                         <v-select
                                            v-model="editedItem.coins"
                                            :disabled="editedItem.isFree || editedItem.isAd"
                                            label="金币"
                                            :items = "coinsCandiate"
                                            item-text = "coins"
                                            item-value = "coins"
                                         >
                                         </v-select>
                                        <!-- <v-text-field
                                            v-model="editedItem.coins"
                                            label="金币"
                                        ></v-text-field> -->
                                    </v-col>
                                    <!-- <v-col cols="6">
                                        <v-select
                                            v-model="editedItem.price"
                                            :disabled="editedItem.isFree"
                                            label="价格"
                                            :items = "priceCandiate"
                                            item-text = "price"
                                            item-value = "price"
                                        >
                                        </v-select>
                                        
                                    </v-col> -->
                                    <v-col cols="12">
                                        <v-select
                                            v-model="editedItem.collections"
                                            :items="collectionCandiate"
                                            label="图集"
                                            item-text="name"
                                            item-value="name"
                                            multiple
                                            chips
                                        ></v-select>
                                    </v-col>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="editedItem.regions"
                                                label="可见地区"
                                                :items="regionCandiate"
                                                item-text="nameCN"
                                                item-value="alpha2"
                                                multiple
                                                chips
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="4">
                                            <v-menu
                                                v-model="datePickerMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedItem.onlineDate"
                                                    label="上线日期"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="editedItem.onlineDate"
                                                @input="datePickerMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    

                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="cancelPicture"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="savePicture"
                            >
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
           </v-dialog>             
           </v-toolbar>
           
       </template>
      

       </v-data-table>
    </v-container>
</template>


<script>
import axios from 'axios'
import MiscUtils from '@/utils/miscUtils'
import RegionUtils from '@/utils/regionUtils'
import multiuploadfile from '@/components/widget/MultiUploadFile'
import {bus} from '@/main'

export default {
    name: 'JigsawLibraryConfig',
    components: {
       'multi-uploadfile': multiuploadfile,
    },

    data: function(){
        return {
            headers: [
                {
                    text: "选择",
                    align: "center",
                    sortable: false,
                    width: "5%",
                    value: "select",
                },
                {
                    text: "Id",
                    align: "center",
                    sortable: false,
                    value: "id",
                    width: "10%",

                },
                {
                   text: "Hash",
                   align: "center",
                   value: "hash", 
                   width: "10%",
                },
                {
                    text: "预览",
                    align: "center",
                    value: "url",
                    width: "5%",
                },
                {
                    text: "地域",
                    align: "center",
                    value: "regions",
                    width: "5%",
                },
                {
                    text: "图集",
                    align: "center",
                    value: "collections",
                    width: "10%",
                },
                {
                    text: "免费",
                    align: "center",
                    value: "isFree",
                    width: "7%",
                },
                {
                    text: "上线时间",
                    align: "center",
                    value: "onlineDate",
                    width: "10%",
                },
                {
                    text: "修改时间",
                    align: "center",
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    value: "actions",
                    width: "10%",
                }
            ],
            items: [],
            search: "",
            loading: true,
            dialog: false,
            dialogMultiModify:false,
            modifiedOnlineDate: "",
            options:{},
            multiselect: false,
            multiupload: false,
            multiselectItems: [],
            serverLength:0,
            date_picker_menu: false,
            editedItem: {
                url: '',
                hash: '',
                isFree: true,
                price: 0,
                onlineDate: "",
                collections: [],
                regions:['AA'],
            },
            coinsCandiate: [
                {tier: 0, coins: 0},
                {tier: 1, coins: 50}
            ],
            priceCandiate: [
                {tier: 1, price: 0.99, currency: "USD"},
                {tier: 2, price: 1.99, currency: "USD"},
                {tier: 3, price: 2.99, currency: "USD"},
                {tier: 4, price: 3.99, currency: "USD"},
                {tier: 5, price: 4.99, currency: "USD"},
            ],
            urlRules: MiscUtils.rules.urlRules,
            regionCandiate: RegionUtils.regions,
            isModifying: false,
            dialogDelete: false,
            showNoData: false,
            magnifyImgUrl: '',
            magnifyImgDialog: false,
            datePickerMenu: false,
            collectionCandiate:[
                // {name:"Scene"},
                // {name:"Carton"},
                // {name:"Masterpiece"},
                // {name: "Plants"},
                // {name: "Animal"},
                // {name: "Pets"},

            ]
        }
    },

    watch: {
        options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

    },

    computed: {
        isFreeLable:function(){
            return this.editedItem.isFree ? "免费": "收费";
        },
        dialogTitle: function(){
            return this.isModifying?"修改Picture":"新建Picture";
        },
    },

    methods: {
        handleIsFreeChange: function(item){
            var vm = this;
            var formData = new FormData()
            formData.append("id", item.id)
            formData.append("isFree", item.isFree)
            axios.post("/admin/api/changeIsFree", formData)
                .then(function(response){
                    if(response.data.status == 0){
                        bus.$emit("showSnackMessage", "修改成功");
                    }else{
                        bus.$emit("showSnackMessage", "修改失败" + response.data.msg);
                    }
                    vm.reloadData();
                });
        },
        makefirst: function(item){
            var vm = this;
            var formData = new FormData()
            formData.append("id", item.id)
            axios.post("/admin/api/makefirstPicture", formData)
                .then(function(response){
                    console.log(response);
                    if(response.data.status == 0){
                        bus.$emit("showSnackMessage", "置顶成功");
                    }else{
                        bus.$emit("showSnackMessage", "置顶失败" + response.data.msg);
                    }
                    vm.reloadData();
                })
        },
        deleteItemConfirm:function(){
            var vm = this;
            var formData = new FormData();
            formData.append("id", this.editedItem.id);
            axios.post('/admin/api/delPicture', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialogDelete = false;
                vm.reloadData();
                bus.$emit("showSnackMessage", "删除成功")
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "删除失败 " + error, true)
                vm.reloadData();
            });
        },
        magnifyImage: function(item){
            this.magnifyImgUrl = item.url;
            this.magnifyImgDialog = true;
        },
        getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
        closeDelete: function(){
            this.dialogDelete = false;
        },
        getTimeStrFromTimestamp(timestamp){
            var s = new Date(timestamp)
            return s.toLocaleString("zh-CN")
        },
        pagination: function(page){
            console.log("pagination param is :" + page);
        },
        getIsFreeColor: function(isFree){
            return isFree?"green":"orange";
        },
        getIsAdColor: function(isAd){
            return !isAd?"green":"orange";
        },
        getIsAdContent: function(isAd) {
            return isAd? "看": "不看";
        },
        getIsFreeContent: function(isFree){
            return isFree?"免费":"非免费";
        },
        fileuploadChange: function(files){
            console.log(files)
            var formData = new FormData();
            formData.append("image", files);
            var vm = this;
            axios.post("/admin/api/uploadFile", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.status == 0){
                    vm.editedItem.url = response.data.data.url;
                    vm.editedItem.hash = response.data.data.hash;
                }else{
                    bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                }
              
            }).catch(function(){
                bus.$emit("showSnackMessage", "图片上传失败 ", true)
                console.log("upload failed");

            })
        },

        clearEditedItem: function(){
            this.editedItem =  {
                url: '',
                hash: '',
                isFree: true,
                onlineDate:"",
                price: 0,
                collections: [],
                regions:['AA'],
            };
        },

        editItem: function(item){
            var index = this.items.indexOf(item);
            if (index !== -1){
                this.isModifying = true;
                this.editedItem = Object.assign({}, item);
                console.log(this.editedItem);
                this.dialog = true;
            }else{
                this.isModifying = false;
            }
           
        },

        deleteItem: function(item){
            console.log("delete item clicked");
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        addPicture: function(){
            var vm = this;
            var formData = new FormData();
            for ( var key in this.editedItem ) {
                var item = this.editedItem[key];
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/addPicture', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialog = false;
                bus.$emit("showSnackMessage", "新增成功")
                vm.reloadData();
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                vm.reloadData();
            });
        },

        modPicture: function(){
            var vm = this;
            var formData = new FormData();
             for ( var key in this.editedItem ) {
                var item = this.editedItem[key];
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/modPicture', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialog = false;
                bus.$emit("showSnackMessage", "修改成功")
                vm.reloadData();
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "修改失败 "+error, true);
                vm.reloadData();
            });
        },

        getOnlineDateColor: function(onlineDate){
            var currentDateStr = new Date().toISOString().split('T')[0];
            if(onlineDate == currentDateStr){
                return "blue";
            }
            if (onlineDate < currentDateStr){
                return "green";
            }else{
                return "";
            }
        },

        doMultiModifyDelete: function(){
            var multiModifyOnlineDateForm = new FormData();
            multiModifyOnlineDateForm.append("ids", this.multiselectItems.join(","));
            var vm = this;
            axios.post('/admin/api/multiModifyDelete', multiModifyOnlineDateForm, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.dialogMultiModify = false;
                vm.multiselectItems = [];
                bus.$emit("showSnackMessage", "修改成功")
                vm.reloadData()
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "修改失败 "+error, true)
                vm.reloadData()
            });
        },

        doMultiModifyOnlineDate: function(){
            var multiModifyOnlineDateForm = new FormData();
            multiModifyOnlineDateForm.append("ids", this.multiselectItems.join(","));
            multiModifyOnlineDateForm.append("onlineDate", this.modifiedOnlineDate);
            axios.post('/admin/api/multiModifyOnlineDate', multiModifyOnlineDateForm, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                bus.$emit("showSnackMessage", "修改成功")
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "修改失败 "+error, true)
            });
        },

        savePicture: function(){
           if(this.isModifying){
               this.modPicture();
           }else{
               this.addPicture();
           }
           
        },
        cancelPicture: function(){
            this.clearEditedItem();
            this.isModifying = false;
            this.dialog = false;
        },

        isFreeChange: function(){
            if(this.editedItem.isFree){
                this.editedItem.isAd = false;
                this.editedItem.coins = 0;
            }
        },
        
        reloadData: function(){
            const { page, itemsPerPage } = this.options
            // console.log(page)
            // console.log(itemsPerPage)
            var vm = this;
            axios.get('/admin/api/listPicture?page='+(page-1)+'&count='+itemsPerPage)
                .then(function(response){
                    vm.items = response.data.data.list;
                    vm.loading=false;
                    vm.serverLength = response.data.data.totalCnt;
                    
            });
            axios.get("/admin/api/listCollection")
                .then(function(response){
                    if(response.data.status == 0){
                        vm.collectionCandiate = response.data.data;
                    }
            });
        },

          
     
    },
    mounted: function(){
       this.reloadData();
    }
}
</script>

<style>
.test{
    background-color: blue;
}
</style>
